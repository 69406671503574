<template>
    <div class="base-container">
        <div class="main-wrapper bg-white">
            <div class="title">关联施工单位分析 </div>
            <div class="gap-line"></div>
            <el-tabs v-if="renderChart" v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="图表" name="chart">
                    <construction-chart ref="chart" :chartData="chartData"></construction-chart>
                </el-tab-pane>
                <el-tab-pane label="表格" name="table">
                    <construction-table :tableData="tableData"></construction-table>
                </el-tab-pane>
            </el-tabs>
            <div class="noData" v-else></div>
        </div>
    </div>
</template>

<script>
import constructionChart from "@/views/associatedConstruction/chart"; //分析页通用echarts关系图展示页
import constructionTable from "@/views/associatedConstruction/table";
import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from "vuex"; //分析页通用table表格展示页
export default {
    components: {
        constructionChart,
        constructionTable,
    },
    data() {
        return {
            renderChart:false,
            renderTable: false,
            activeName: "chart",
            chartData: {},
            tableData: [],
        }
    },
    computed: {},
    created() {
        this.requestData();
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        back() {
            this.reduceMenuList();//删除历史菜单中最后一个元素（当前页的路由）
            this.reducePath();//删除导航最后一个路由
            var currentMenu = getStore("currentMenu");
            this.$router.push({path: currentMenu});
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                data: {
                    pageName:'building',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        requestData() {
            var companyName = getStore('thisProject').taxpayerName;
            var projectId = getStore('thisProject').id;
            var creditCode = getStore('thisProject').taxpayerCode;
            this.axios({
                method: "get", url: '/companyRelation/relationCheck',
                params: {
                    companyName: companyName,
                    projectId: projectId,
                    creditCode: creditCode
                },
            }).then((res) => {
                //console.log(res.data)
                if(res.data.data.overview){
                    this.chartData = res.data.data.overview;
                    this.tableData = res.data.data.detail;
                    this.renderChart = true;
                }
                
            }).catch((error) => {
                console.log(error)
            })
        },
        handleClick(tab) {
            this.getReport(2,'associatedConstruction',this.activeName)
            if (tab.name == 'table') {
                this.renderTable = true;
            }
        }
    }
}
</script>

<style scoped lang="less">
.title{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}
.gap-line{
    height: 1px;
    background: #eceaea;
    margin-bottom: 15px;

}
.main-wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 15px;

    .nullrelation {
        position: absolute;
        width: 250px;
        height: 156px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: url('../../assets/img/nullRelation.png') no-repeat;
    }

    /deep/ .el-tabs {
        height: 100%;
    }

    /deep/ .el-tabs--card > .el-tabs__header {
        border-bottom: none;
    }


    /deep/ .el-tabs__content {
        width: 100%;
        height: calc(100% - 52px);
        position: relative;
    }

    /deep/ .el-tabs__item {
        width: 44px;
        height: 26px;
        line-height: 26px;
        padding: 0 7px !important;
        background: rgba(50, 50, 51, 0.06);
        border-radius: 2px 0 0 2px;
        border: 1px solid rgba(50, 50, 51, 0.06);
    }

    /deep/ .el-tab-pane {
        height: 100%;
    }

}


</style>