<template>
    <div id="construction-table">
        <el-table
            border
            :data="listData"
            @sort-change="sortChange"
            stripe
            :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
            <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50">
            </el-table-column>
            <el-table-column
                prop="companyName"
                width="250"
                label="建设单位"
                 align="left"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
                prop="otherCompanyName"
                idth="210"
                 align="left"
                label="施工单位"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column
                prop="contractCost"
                align="right"
                width="140"
                sortable='custom'
                label="合同金额(万元)">
            </el-table-column>
            <el-table-column
                prop="contractCostTotal"
                align="right"
                width="160"
                sortable='custom'
                label="合同总金额(万元)">
            </el-table-column>
            <el-table-column
                prop="costRate"
                align="right"
                width="110"
                sortable='custom'
                label="占比">
            </el-table-column>
            <el-table-column
                prop="description"
                label="详情"
                :show-overflow-tooltip="true">
            </el-table-column>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                background
                layout="prev,pager,next,total,jumper"
                :total=total
                :current-page=pageNum>
            </el-pagination>
        </div>
    </div>

</template>

<script>
import {getStore,setStore,getLocalStore} from "@/util/store";
export default {
    props: {
        tableData:[]
    },
    data(){
        return {
            tableHeight:0,
            listData: [],
            pageNum: 1,
            total: 0,
        }
    },
    created() {
        this.$nextTick(()=>{
            this.getTableHeight();
        })
    },
    watch:{
        tableData(){
            for(let i = 0;i<this.tableData.length;i++){
                this.tableData[i].delcommafyContractCostTotal= this.delcommafy(this.tableData[i].contractCostTotal);
                this.tableData[i].delcommafyContractCost= this.delcommafy(this.tableData[i].contractCost);
            }
            let arr =[].concat(this.tableData);
            this.listData = arr.splice((this.pageNum-1)*10,10);
            this.total = this.tableData.length;
        }
       
    },
    mounted() {
        for(let i = 0;i<this.tableData.length;i++){
            this.tableData[i].delcommafyContractCostTotal= this.delcommafy(this.tableData[i].contractCostTotal);
            this.tableData[i].delcommafyContractCost= this.delcommafy(this.tableData[i].contractCost);
        }
        let arr =[].concat(this.tableData);

        this.listData = arr.splice((this.pageNum-1)*10,10);
        this.total = this.tableData.length;
    },
    methods:{
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                data: {
                    pageName:'building',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        getTableHeight() {
            var height = document.getElementById('construction-table').offsetHeight;
            this.tableHeight = height
        },
        delcommafy(num) {
            num=num.replace(/,/gi,'');
            return num-0;
        },
         //更改页码
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getReport(2,'associatedConstruction',val)
            let arr =[].concat(this.tableData);
            this.listData = arr.splice((this.pageNum-1)*10,10);
            this.total = this.tableData.length;
        },
        sortContractCost(a,b){
            return a.delcommafyContractCost - b.delcommafyContractCost;
        },
        sortContractCostTotal(a,b){
            return a.delcommafyContractCostTotal - b.delcommafyContractCostTotal;
        },
        sortChange({ prop, order }) {
            if(prop=='contractCost'){
                this.tableData.sort(this.compare("delcommafyContractCost",order));
            }else if(prop=='contractCostTotal'){
                this.tableData.sort(this.compare("delcommafyContractCostTotal",order));
            }else{
                this.tableData.sort(this.compare(prop,order));
            }
            this.pageNum = 1;

        },
        compare (propertyName, sort) {
            return function (obj1, obj2) {
                var value1 = obj1[propertyName]
                var value2 = obj2[propertyName]
                if (typeof value1 === 'string' && typeof value2 === 'string') {
                    const res = value1.localeCompare(value2, 'zh')
                    return sort === 'ascending' ? res : -res
                } else {
                    if (value1 <= value2) {
                        return sort === 'ascending' ? -1 : 1
                    } else if (value1 > value2) {
                        return sort === 'ascending' ? 1 : -1
                    }
                }
            }
        }
    }

}
</script>

<style scoped>
#construction-table {
    height: 100%;
}
</style>